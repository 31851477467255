import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './Blog.css';
import { blogData } from '../../utils/data.js';
import NewPageNavbar from '../NewPageNavbar/NewPageNavbar.jsx';

const Blog = () => {
  return (
    <>
      <NewPageNavbar />
     <section>
       <Row>
        <Col md={12}>
        <div>
        <div className='blog-image-container'>
           <img className='blog-images' src='/blog/blog-hero.webp' alt='blog' />
        </div>

            <div style={{ 
              position: 'absolute', 
              top: '40%', 
              left: '50%', 
              color:'white',
              zIndex: '2',
              transform: 'translate(-50%, -50%)',
              padding: '20px 30px', 
              borderRadius: '5px' 
            }}>
              <h3 className='mb-0'>Our Blog</h3>
            </div>
          </div>
        </Col>
      </Row>
      <Container>
        <Row className="my-3">
          {blogData.slice(0, 3).map((image) => (
            <Col key={image.id} xs={12} md={4} className="mb-3">
              <Link to={`/blog/${image.slug}`} style={{ textDecoration: 'none' }}>
                <Card className="blog-image-containers">
                  <Card.Img className="blog-image" variant="top" src={image.heroblog} alt={image.title} />
                  <div className="image-overlay">
                    <Card.Title className="image-titles multi-line-ellipsis">{image.title}</Card.Title>
                  </div>
                </Card>
              </Link>
            </Col>
          ))}
        </Row>

        <Row className="my-3 px-3">

          {/* Blog image 4 */}

          {blogData.slice(3).map((image) => (
            <Col xs={12} md={2} className="mb-3">
              <Link to={`/blog/${image.slug}`} style={{textDecoration:'none'}}>
                <Card className="blog-image-containers">
                  <Card.Img className="blog-image" style={{height:'20vh'}} variant="top" src={image.heroblog} alt={image.alt}/>
                  <div className="image-overlay">
                    <Card.Title className="image-titles multi-line-ellipsis">{image.title}</Card.Title>
                  </div>
                </Card>
              </Link>
            </Col>
            ))}

        </Row>
      </Container>
     </section>

    </>
  );
};

export default Blog;
