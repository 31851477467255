
import React, { useEffect } from "react";
import '../App.css'
import Navbar from '../components/Navbar/Navbar';
import Hero from '../components/Hero/Hero';
import BrandingVideo from '../components/BrandingVideo/BrandingVideo';
import WhatWeDo from '../components/WhatWeDo/WhatWeDo';
import OurDiff from '../components/OurDiff/OurDiff';
import { motion, useAnimation } from 'framer-motion';
import HowItWorks from '../components/HowItWorks/HowItWorks';
import WhoWeInvest from '../components/WhoWeInvest/WhoWeInvest';
import Testimonials from '../components/Testimonials/Testimonials';

function HomeScreen() {

  const controls = useAnimation();
  const ourDiffControls = useAnimation();
  const whoWeInvestControls = useAnimation();

  useEffect(() => {
    controls.start({
      opacity: 1,
      transition: { duration: 2 }
    });
  }, [controls, ourDiffControls, whoWeInvestControls]);

  return (
    <>

    <motion.div className="app" animate={controls}>
      <Navbar />
      <Hero />
      <BrandingVideo />
      <WhatWeDo />
      <motion.div
      onViewportEnter={()=> 
        ourDiffControls.start({
          backgroundColor: "var(--secondary-color)",
        })
      } 
      onViewportLeave={()=> ourDiffControls.start({
        backgroundColor: "white",
      })}
      viewport={{amount: 0.4}}
      animate={ourDiffControls}
      >
        <OurDiff />
      </motion.div>
      <HowItWorks />

      <motion.div
      onViewportEnter={()=> 
        whoWeInvestControls.start({
          backgroundColor: "var(--primary-color)",
        })
      } 
      onViewportLeave={()=> whoWeInvestControls.start({
        backgroundColor: "white",
      })}
      viewport={{amount: 0.4}}
      animate={whoWeInvestControls}
      >
        <WhoWeInvest />
      </motion.div>

      <Testimonials />  
      
    </motion.div>

    </>
  );
}
export default HomeScreen;
