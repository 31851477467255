import React from 'react'
import { Carousel, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../ImageDetail.css'
import { FaCheck } from 'react-icons/fa';
import NewPageNavbar from '../../NewPageNavbar/NewPageNavbar';

const CommonBookKeeping = () =>{
    
  const listStyle = {
    listStyleType: 'none',
  };

    return(
        <>
        <NewPageNavbar />
        <Row className='me-0'>
        <div className='hero-images'>
          <Col md={12} className='p-0'>
           
              <div className='image-container'>
                <img className='blog-images'
                  src='/blog/blog9.webp'
                  alt='heroImage'
                  style={{ width: '100%' }} 
                />
          
                <div style={{ 
                  position: 'absolute', 
                  top: '50%', 
                  left: '5%', 
                  transform: 'translateY(-50%)', 
                  zIndex: '3',
                  color:'white',
                  borderRadius: '5px',              
                }}>

                  <Row className='m-0'>
                    <Col xs={11} sm={11} md={8} className='pb-xs-5'>
                      <div className=''>
                        <h1 className='imageDetail-title'> Common Bookkeeping Mistakes That Businesses Make in UAE </h1>
                        <p className='mt-5 imageDetail-para' style={{color:'white'}}>
                        Common bookkeeping mistakes can cost businesses in UAE and damaged relationships with clients. Learn how to avoid these common mistakes by reading this blog.
                        </p>
                      </div>
                    <div style={{textAlign:'right'}}>
                    <p className='mb-0'>
                        <Link to='/' className='blog-home'><span style={{cursor:"pointer"}} >Home</span></Link>
                        <span className='mx-2'>|</span>
                        <Link to='/blog' className='blog-home'><span style={{cursor:"pointer"}}>Blog</span></Link>
                      </p>
                    </div>
                    </Col>
                  </Row>
                  </div>
                </div> 
            
          </Col>
          </div>       
        </Row>

 {/* <Container> */}
 <Row className='m-5'>
        <Col md={12} lg={8}>
            <div className=''>
                <div>
                  <h2 className='imageDetail-title'>What is Accounting & Bookkeeping?</h2> 
                  <p className='imageDetail-para'  style={{textAlign:'justify'}}>
                  Bookkeeping is essential for business owners to maintain accurate financial records and make sound business decisions. Poor accounting is one of the leading causes of business failure. Without bookkeeping, businesses cannot effectively manage their finances. The best way to avoid common bookkeeping mistakes is to hire a certified bookkeeper. A bookkeeper can dedicate themselves to ensuring that your books are kept accurately. Bookkeeping is an essential part of running a business, and it helps to track the financial position and profitability of the business.
                  </p>

                  <p className='imageDetail-para'  style={{textAlign:'justify'}}>
                  If you are a small business owner, you know the challenges of juggling sales, marketing, product development, and operations all at the same time. It is important not to neglect bookkeeping, as this can be a costly mistake. For any business to be successful, good bookkeeping practices are essential. Good bookkeeping can give you the financial insights you need to make smart business decisions, avoid failures, and grow your business rapidly.
                   </p>

                </div>
              
                <div>
                    <h2 className='imageDetail-title'>Bookkeeping Services Includes:</h2>

                     <ul style={listStyle}>
                      <li><b><FaCheck className='me-3 facheck'/><span className='imageDetail-para'>
                      <span className="imageDetail-para paralist-head">Accounts receivable services:</span> Managing customer payments
                        </span></b></li>

                        <li><b><FaCheck className='me-3 facheck'/><span className='imageDetail-para'>
                        <span className="imageDetail-para paralist-head">Accounts payable services: </span>Managing payments to vendors
                        </span></b></li>
                        
                        <li><b><FaCheck className='me-3 facheck'/><span className='imageDetail-para'>
                        <span className="imageDetail-para paralist-head">Petty cash transaction services: </span>Tracking and managing petty cash expenses
                        </span></b></li>
                        
                        <li><b><FaCheck className='me-3 facheck'/><span className='imageDetail-para'>
                        <span className="imageDetail-para paralist-head">Bank account reconciliation:</span> Matching bank statements to accounting records
                        </span></b></li>
                        
                       <li><b><FaCheck className='me-3 facheck'/><span className='imageDetail-para'>
                       <span className="imageDetail-para paralist-head">Invoicing processing services: </span> Creating and sending invoices to customers
                        </span></b></li>

                        <li><b><FaCheck className='me-3 facheck'/><span className='imageDetail-para'>
                        <span className="imageDetail-para paralist-head">Manual journal entry services:</span> Recording financial transactions in the general ledger
                        </span></b></li>

                        <li><b><FaCheck className='me-3 facheck'/><span className='imageDetail-para'>
                        <span className="imageDetail-para paralist-head">VAT returns:</span> Filing VAT returns with the government
                        </span></b></li>

                        <li><b><FaCheck className='me-3 facheck'/><span className='imageDetail-para'>
                        <span className="imageDetail-para paralist-head">Inventory management:</span> Tracking and managing inventory levels
                        </span></b></li>

                        <li><b><FaCheck className='me-3 facheck'/><span className='imageDetail-para'>
                        <span className="imageDetail-para paralist-head">General ledger maintenance: </span>Maintaining the general ledger, which is the master record of all financial transactions
                        </span></b></li>

                        <li><b><FaCheck className='me-3 facheck'/><span className='imageDetail-para'>
                        <span className="imageDetail-para paralist-head">Assets / equipment ledger maintenance:</span> Tracking and managing assets and equipment
                        </span></b></li>

                  <p className='imageDetail-para'  style={{textAlign:'justify'}}>
                  Bookkeeping helps business owners make smart, informed decisions by providing them with accurate financial information. This information can be used to track income and expenses, identify trends, and make informed decisions about the business. Bookkeeping is an ongoing process that should be performed regularly to save time and prevent headaches. During bookkeeping, there is a chance of making mistakes, but some mistakes can be prevented by following best practices. If a mistake is discovered, it should be corrected immediately.    
                </p>

               
                    </ul>
                </div> 

                <div>
                    <h2 className='imageDetail-title mt-3'>Common Bookkeeping Mistakes in UAE</h2>
                  <p className='imageDetail-para'  style={{textAlign:'justify'}}>
                  Many businesses and individuals in Dubai make common bookkeeping mistakes that can have serious consequences in the future. These mistakes may seem minor at first, but they can grow into bigger problems over time. Here are some of the most common bookkeeping mistakes in Dubai to avoid:
                    </p>
                      <ul style={{textAlign:"justify"}}>
                          <li style={listStyle}><b><FaCheck className="me-3 facheck" />
                               <span className="imageDetail-para paralist-head"> Not Hiring a Professional Bookkeeper: </span></b>
                               
                               <p className='imageDetail-para'  style={{textAlign:'justify'}}>
                                 Bookkeeping can be complex, so it is important to hire a professional bookkeeper to handle your accounts. A professional bookkeeper can ensure that your books are accurate and up-to-date, and they can also help you to comply with UAE tax laws and regulations.
                               </p>
                          </li>

                          <li style={listStyle}><b><FaCheck className="me-3 facheck" />
                               <span className="imageDetail-para paralist-head"> Not using accounting software:  </span></b>
                               
                               <p className='imageDetail-para'  style={{textAlign:'justify'}}>
                               Accounting software can automate many of the tasks involved in bookkeeping, such as recording transactions, generating invoices, and preparing financial reports. This can save you time and reduce the risk of errors.
                               </p>
                          </li>

                          <li style={listStyle}><b><FaCheck className="me-3 facheck" />
                               <span className="imageDetail-para paralist-head">Not keeping accurate records: </span></b>
                               
                               <p className='imageDetail-para'  style={{textAlign:'justify'}}>
                                It is important to keep accurate records of all of your financial transactions. This includes invoices, receipts, and bank statements. Accurate records will help you to track your income and expenses, and they will also be necessary for tax purposes.
                               </p>
                          </li>

                          <li style={listStyle}><b><FaCheck className="me-3 facheck" />
                               <span className="imageDetail-para paralist-head"> Not reconciling your accounts: </span></b>
                               
                               <p className='imageDetail-para'  style={{textAlign:'justify'}}>
                               Bank reconciliation is the process of matching your bank statements to your accounting records. This is important to ensure that your books are accurate and to identify any errors or discrepancies.
                               </p>
                          </li>

                          <li style={listStyle}><b><FaCheck className="me-3 facheck" />
                               <span className="imageDetail-para paralist-head">  Not paying taxes on time:</span></b>
                               
                               <p className='imageDetail-para'  style={{textAlign:'justify'}}>
                               It is important to pay your taxes on time to avoid penalties and interest charges. You should also file your tax returns accurately and on time.
                               </p>
                          </li>

                          <li style={listStyle}><b><FaCheck className="me-3 facheck" />
                               <span className="imageDetail-para paralist-head">Not backing up data: </span></b>
                               
                               <p className='imageDetail-para'  style={{textAlign:'justify'}}>
                                Data loss is a nightmare for any business. Failing to regularly back up your financial data can lead to catastrophic consequences. Implement a reliable backup system to safeguard your records.
                               </p>
                          </li>

                          <li style={listStyle}><b><FaCheck className="me-3 facheck" />
                               <span className="imageDetail-para paralist-head"> Forgetting petty cash:  </span></b>
                               
                               <p className='imageDetail-para'  style={{textAlign:'justify'}}>
                               Small cash expenses can add up quickly. Neglecting to track and reconcile petty cash transactions can create discrepancies and make it difficult to account for all your expenditures accurately.
                               </p>
                          </li>

                          <li style={listStyle}><b><FaCheck className="me-3 facheck" />
                               <span className="imageDetail-para paralist-head"> Delayed invoicing: </span></b>
                               
                               <p className='imageDetail-para'  style={{textAlign:'justify'}}>
                               Delayed invoicing: Timely invoicing is essential for maintaining a healthy cash flow. Delayed invoicing can lead to delayed payments, affecting your working capital. Implement a system to ensure invoices are sent promptly.
                               </p>
                          </li>
                        </ul>
                </div>

                <div>
                  <h2 className='imageDetail-title'>Conclusion</h2> 
              
                  <p className='imageDetail-para'  style={{textAlign:'justify'}}>
                  Avoiding these common bookkeeping mistakes is crucial for businesses operating in the UAE. It not only ensures legal compliance but also contributes to better financial health and decision-making. Consider seeking professional assistance or investing in accounting software to streamline your bookkeeping processes and safeguard your business's financial future. Remember, accurate bookkeeping is a key step toward achieving long-term success in the competitive UAE business landscape.
                  </p>
                </div>
            </div>   
    </Col>

{/* RECENT POST */}
    <Col md={12} lg={4} className='mt-2'>
      <h5 className='recentpost-heading'>RECENT POST</h5>
        <Row>
              <Col xs={12} sm={12} md={6} lg={12} className='pt-3'>
                <div className='recent_post mt-4 mb-3'>

                    <Link to={'/blog'} className='link-text'>
                      <div className='recent-image' style={{width:'100px', height:'70px'}}>
                        <img style={{width:"100%", height:"100%"}} src='/blog/bookkeeping.webp' alt='bookkeeping' />
                      </div>
                    </Link>

                    <Link to={'/blog'} className='link-text'>
                      <div className='ps-3'>
                        <h6 className='link-heading'>Common Bookkeeping Mistakes in UAE</h6>
                        {/* <h6 className='date'> </h6> */}
                      </div>
                    </Link>
                </div>
                </Col>
                <Col xs={12} sm={12} md={6} lg={12} className='pt-3'>
                <div className='recent_post mt-4 mb-3'>

                    <Link to={'/blog'} className='link-text'>
                      <div className='recent-image' style={{width:'100px', height:'70px'}}>
                        <img style={{width:"100%", height:"100%"}} src='/blog/assests.webp' alt='equipment' />
                      </div>
                    </Link>

                    <Link to={'/blog'} className='link-text'>
                      <div className='ps-3'>
                        <h6 className='link-heading'>Assets / equipment ledger maintenance</h6>
                        {/* <h6 className='date'> </h6> */}
                      </div>
                    </Link>
                </div>
              </Col>

              <Col xs={12} sm={12} md={6} lg={12} className='pt-3'>
                <div className='recent_post mt-4 mb-3'>

                    <Link to={'/blog'} className='link-text'>
                      <div className='recent-image' style={{width:'100px', height:'70px'}}>
                        <img style={{width:"100%", height:"100%"}} src='/blog/bank-account.webp' alt='bank-account' />
                      </div>
                    </Link>

                    <Link to={'/blog'} className='link-text'>
                      <div className='ps-3'>
                        <h6 className='link-heading'>Bank account reconciliation matching bank statements</h6>
                        {/* <h6 className='date'> </h6> */}
                      </div>
                    </Link>
                </div>
              </Col>
              
              <Col xs={12} sm={12} md={6} lg={12} className='pt-3'>
                <div className='recent_post mt-4 mb-3'>

                    <Link to={'/blog'} className='link-text'>
                      <div className='recent-image' style={{width:'100px', height:'70px'}}>
                        <img style={{width:"100%", height:"100%"}} src='/blog/proficient-bookkeeper.webp' alt='proficient-bookkeeper' />
                      </div>
                    </Link>

                    <Link to={'/blog'} className='link-text'>
                      <div className='ps-3'>
                        <h6 className='link-heading'>The Benefits of Hiring a Professional Bookkeeper for UAE Tax Compliance</h6>
                        {/* <h6 className='date'> </h6> */}
                      </div>
                    </Link>
                </div>
              </Col>
          
        </Row>

        {/*  */}

        <Row>
          <Col md={12}>
              <div className='my-5 recommed'  style={{backgroundColor:"blue", width: '100%', height:'50vh'}}>
                <div className='p-5'>
                    <h3><b>COMMON BOOKKEEPING MISTAKES IN BUSINESSES</b></h3>
                    <p className='pt-4'>Proactive accounting services in UAE is a customized approach to optimizing business profits by leveraging technology and trends.</p>
                </div>
              </div>
          </Col>
        </Row>


{/* FEATURED POST */}

        <Row>
          <Col md={12} className='pt-5'>
          <h5 className='recentpost-heading'>FEATURED POST</h5>

            <Carousel className='mt-4'>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src="/blog/accounts-receivable.webp"
                  alt="accounts-receivable"
                />

                <div>
                  <h4 className='mt-3' style={{color:"black"}}><b>Accounts receivable services</b></h4>
                </div>
              </Carousel.Item>

              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src="/blog/invoicing-service.webp"
                  alt="invoicing-service"
                />
                <div>
                  <h4 className='mt-3' style={{color:"black"}}><b>Invoicing processing services</b></h4>
                </div>
              </Carousel.Item>

              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src="/blog/reconciliation.webp"
                  alt="reconciliation"
                />
                <div>
                  <h4 className='mt-3' style={{color:"black"}}><b>Check for technology integration</b></h4>
                </div>
              </Carousel.Item>
              
            </Carousel>
       
          </Col>
        </Row>
    </Col>
  </Row>
 {/* </Container> */}

</>
    )
}

export default CommonBookKeeping;