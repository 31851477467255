import React, { useEffect } from 'react';
import './Footer.css';
import { FaLocationArrow, FaPhone, FaEnvelope } from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';
import { Footerimages } from '../../utils/data';

const Footer = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div className="f-wrapper">
      <div className="container">
        <div className="f-container">
          <div className="f-menu">
            <div>
              <Link to='/' className='footer-link footer-link-home'><p>HOME</p></Link>
              <Link to='/corporatetax' className='footer-link'><p>Corporate Tax</p></Link>
              <Link to='/blog' className='footer-link'><p>Blog</p></Link>
              <Link to='/contact' className='footer-link'><p>Contact</p></Link>
            </div>

            <div>
              <Link to='/blog' className='footer-link'><p>BLOG</p></Link>
              {Footerimages.map((image) => (
                <Link key={image.id} to={`/blog/${image.slug}`} className='footer-link'>
                  <p>{image.footertext}</p>
                </Link>
              ))}
            </div>

            <div>
              <Link to='/contact' className='footer-link'><p>CONTACT</p></Link>
              <div className="contact-info">
                <p className='mb-0'>
                  <FaLocationArrow className='me-2' /> Office 903, 9th floor, Damas Building,
                </p>
                <p className='ms-4'>Al Maktoum Road, Dubai, UAE</p>
              </div>
              <div className="contact-info">
                <p className='mb-0'>
                  <FaPhone className='me-2' /> <Link to='tel:+971528722900' className='tellto'>+971 52 872 2900</Link>
                </p>
                <p className='ms-4'><Link to='tel:+97142253534' className='tellto'> +971 42 253 534</Link></p>
              </div>
              <p><FaEnvelope className='me-2' /><Link to='mailto:contact@maximsauditors.ae' className='tellto'>contact@maximsauditors.ae</Link></p>
            </div>
          </div>
          <hr />
          <span className='text'>
            Developed by Stew&Jones Technologies Pvt Ltd
          </span>
        </div>
      </div>
    </div>
  )
}

export default Footer;
