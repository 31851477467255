import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import './NewPageNavbar.css';
import logo from '../../assets/logos.webp'

const NewPageNavbar = () => {

  return (
    <div className='custom-navbar'>
      <Navbar expand="md" className="py-1 ">
        <Container>
          <Navbar.Brand href="/">
            <img src={logo} alt="Maxims Logo" style={{width: '150px'}} className="logo-image" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto nav-text">
              <Nav.Link href="/"> <span className="nav-text me-3">HOME</span></Nav.Link>
              <Nav.Link href="/corporatetax"><span className="nav-text me-3">CORPORATE TAX</span></Nav.Link>
              <Nav.Link href="/Blog"><span className="nav-text me-3">BLOG</span></Nav.Link>
              <Nav.Link href="/contact"><span className='fund'>Contact</span></Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>

  );
};

export default NewPageNavbar;
