import React from 'react'
import HomeScreen from './screen/HomeScreen'
import { Route, Routes } from 'react-router-dom'
import Contact from './components/Contact/Contact'
import CorporateTax from "./components/CorporateTax/CorporateTax"
import { Navbar } from 'react-bootstrap'
import Blog from './components/Blog/Blog'
import ImageDetail from './components/Blog/ImageDetail'
import Footer from './components/Footer/Footer'
import AccountingTrends from './components/Blog/DetailsComponent/AccountingTrends'
import RoleAccounting from './components/Blog/DetailsComponent/RoleAccounting'
import BestAccounting from './components/Blog/DetailsComponent/BestAccounting'
import RoiAccounting from './components/Blog/DetailsComponent/RoiAcccounting'
import ProactiveAccounting from './components/Blog/DetailsComponent/ProactiveAccounting'
import CommonBookKeeping from './components/Blog/DetailsComponent/CommonBookKeeping'
import CommonCorporateTax from './components/Blog/DetailsComponent/CommonCorporateTax'

const App = () => {
  return (
    <>
    <Navbar />
      <Routes>
        <Route path="/" element={<HomeScreen />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/corporatetax' element={<CorporateTax />} />
          <Route path='/blog' element={<Blog />} />
          <Route path="/blog/:slug" element={<ImageDetail />} />
          <Route path="/blog/accountingtrends" element={<AccountingTrends />} />
          <Route path='/blog/role-of-accounting-in-business' element={<RoleAccounting />}/>
          <Route path='/blog/best-accounting-software-in-UAE' element={<BestAccounting />}/>
          <Route path='/blog/roi-in-accounting' element={<RoiAccounting />}/>
          <Route path='/blog/proactive-accounting-services' element={<ProactiveAccounting />}/>
          <Route path='/blog/common-bookkeeping-mistakes' element={<CommonBookKeeping />}/>
          <Route path='/blog/tax-mistakes-to-avoid-in-uae' element={<CommonCorporateTax />}/>
      </Routes>
      <Footer />
    </>
  )
}

export default App
