import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import './CorporateTax.css'
import corporate from '../../assets/corporatetax.webp'
import { NavLink } from 'react-router-dom'
import icon1 from '../../assets/icons1.webp'
import icon2 from '../../assets/icons2.webp'
import icon3 from '../../assets/icon3.webp'
import icon4 from '../../assets/icon4.webp'
import icon5 from '../../assets/icons5.webp'
import NewPageNavbar from '../NewPageNavbar/NewPageNavbar'


const CorporateTax = () => {
  return (
    <>     
      <NewPageNavbar />

        <div className="ct-wrapper">


            <Container className='mt-5'>
                <Row>
                    <Col md={7}>
                        <div className='p-md-5 p-sm-0'>
                            <h3><b>CORPORATE TAX SERVICE IN UAE</b></h3>
                            <h5 className='py-3'><b>"Your One-Stop Shop for Compilance and Success"</b></h5>

                            <p className='pe-2 pe-sm-0'>Maxims, your trusted partner for corporate tax services in UAE, can help you to
set up your company for success. Our expert consultants are here to guide you
through every step of the tax assessment, registration, and return filing process.
With the introduction of corporate tax in the UAE, the regulatory landscape is
evolving, and it's crucial for companies to be fully prepared to comply with this
new form of taxation.</p>

                           <NavLink to='/contact'> <button className='getaquote px-3 py-2 my-5'>GET A QUOTE</button> </NavLink>
                        </div>
                    </Col>
                    <Col md={5}>
                        <div className='pe-2 pe-sm-0'>
                            <img src={corporate} alt="corporate" style={{width: "100%"}}/>
                        </div>
                    </Col>
                    <Col md={12} className='my-5'>
                        <h3 className='text-center'><b>What is Corporate Tax?</b></h3>
                        <p className='text-center pe-2 pe-sm-0'>
                        Businesses operating in the UAE must abide by government-imposed tax laws and regulations. If taxable income is less than AED 375,000, corporate tax
will be 9%; otherwise, it will be 0%. Businesses and individuals with a commercial license, as well as those in the UAE's Free Zones conducting activities
in the mainland, may be subject to Value Added Tax (VAT). Corporate tax services in UAE, Foreign entities and individuals who engage in regular of
ongoing trade or business activities in the UAE will also be liable. Real estate management, construction, development, agency and brokerage activities are
also part of the scope of VAT.
                        </p>
                        {/* <img src='' alt="CorporateTax"/> */}
                    </Col>

                    <Col md={12} className=''>
                        <h3 className='text-center'><b>The Real Expert in the Field Will Provide</b></h3>
                        <p className='text-center pe-2 pe-sm-0'>
                        Highly qualified tax consultants with many years of experience in the field offer a full range of services to help you build a sound financial future. Timely
received tax advice helps to avoid many problems in the tax process. Our experts will help you as efficiently as possible and in a short time. We help
businesses in the following ways when preparing their corporation taxes;
Corporate Tax Services in UAE
                        </p>
                        {/* <img src='' alt="Real Expert"/> */}

                    </Col>
                </Row>

                <Row className='mt-5'>
                    <div>
                        <h3 className='text-center mb-4'><b>Wide Range of Quality in Finance Service</b></h3>
                    </div>
                    <Col md={6} lg={3} className='mt-4 pe-2 pe-sm-0'>
                        <div className='p-5 wide-range'>
                            <h5 className=''><b>Due Diligence Services</b></h5>
                            <p>Financial</p>
                            <p className=''>The purpose of Due diligence Service in UAE Due Diligence service in UAE is a</p>
                        </div>
                    </Col>

                    <Col md={6} lg={3} className='mt-4 pe-2 pe-sm-0'>
                        <div className='p-5 wide-range'>
                            <h5 className=''><b>Project Reports & Feasibility Studies</b></h5>
                            <p>Financial</p>
                            <p className=''>Project Reports & Feasibility Study in UAE Feasibility Study</p>
                        </div>
                    </Col>

                    <Col md={6} lg={3} className='mt-4 pe-2 pe-sm-0'>
                        <div className='p-5 wide-range'>
                            <h5 className=''><b>Accounting Software Services</b></h5>
                            <p>Financial</p>
                            <p className=''>Accounting Software Services in UAE Maxims offers top-tier</p>
                        </div>
                    </Col>

                    <Col md={6} lg={3} className='mt-4 pe-2 pe-sm-0'>
                        <div className='p-5 wide-range wide-range-Business'>
                            <h5><b>Business Restructuring Services</b></h5>
                            <p>Financial</p>
                            <p className=''>An Introduction to Business Restructuring in UAE Business</p>
                         
                        </div>
                    </Col>
                </Row>
                    <NavLink to="mailto:contact@maximsauditors.ae">
                        <div className='text-center'>
                            <button className='getaquote px-3 py-2 my-5'>FREE CONSULTATION</button>   
                        </div>
                    </NavLink>
                </Container>


                <Row className='p-5 grid-bg'>
                    <div className='mb-4'>
                        <h3 className='text-center'><b>What Benefits we can Add to our Customers</b></h3>
                    </div>

                    <div class="grid-container">
                        <div class="grid-item">
                            <img src={icon1} alt='icon' style={{width: "40px", height:"50px"}} />
                            <p className='pt-3'>By reducing errors and maintaining complicance, we gurantee accurate
                                tax assestment and timily filling.
                            </p>
                        </div>
                        <div class="grid-item">
                            <img src={icon2} alt='icon' style={{width: "50px", height:"45px"}} />
                       
                            <p className='pt-3'>To minimize liabilities, maximize savings, and improve financial efficiency, we use strategic tax planning.
                            </p>
                        </div>
                        <div class="grid-item">
                            <img src={icon3} alt='icon' style={{width: "50px", height:"50px"}} />
                        
                            <p className='pt-3'>We prevent double taxation, protecting your profits and promoting 
                                international trade.
                            </p>
                        </div>
                        <div class="grid-item">
                            <img src={icon4} alt='icon' style={{width: "40px", height:"50px"}} />
                        
                            <p className='pt-3'>We help you save money on taxes so that your finances are more stable and long-lasting.
                            </p>
                        </div>
                        <div class="grid-item">
                            <img src={icon5} alt='icon' style={{width: "40px", height:"50px"}} />
                        
                            <p className='pt-3'>For the purpose of increasing your profit margins, we optimise revenue, 
                                tax management, and resources.
                            </p>
                        </div>
                    </div>
                </Row>

                <div className='maximsAuditors'>
                    <Container>
                        <div className='py-5'>
                            <h3 className='text-center'><b>Why Maxims Auditors for Corporate Tax Services?</b></h3>
                            <p className='pt-4 text-center'>
                            Maxims offers professional corporate tax advice and legal guidance to individuals and businesses in the UAE. Our team of legal professionals is well-versed
                            in the latest UAE tax laws and regulations, and can provide sound advice to residents and immigrants alike. Additionally, our corporate tax advisors are
                            experienced in helping businesses based in the UAE. Reach out to us today, to get advisory services from our experienced tax experts in Dubai, UAE.
                            </p>
                        </div>
                    </Container>
                </div>
        </div>
    </>
  )
}

export default CorporateTax
