import React, { useState } from 'react'
import '../Contact/Contact.css'
import { Col, Container, Form, Row } from 'react-bootstrap'
import { FaEnvelope, FaWhatsapp, FaLinkedin } from 'react-icons/fa';
import NewPageNavbar from '../NewPageNavbar/NewPageNavbar';
import emailjs from '@emailjs/browser';

const Contact = () => {

  const [formData, setFormData] = useState({
    name:'',
    email:'',
    mobile:'',
    message:''
  });

  const handleChange= (e) =>{
    const {name, value} = e.target;
    setFormData({
      ...formData, [name]: value
    });
  }

  const handleSubmit =(e) => {
    e.preventDefault();
    
    const contactDetails = {
      from_name: formData.name,
      from_email: formData.email,
      from_mobile: formData.mobile,
      from_message: formData.message
    };

    emailjs.send(
      'service_mlusfuf',
      'template_k7by9v3',
      contactDetails,
      'uP5sawhTLJd8BxkiC'
    ).then((response) => {
      console.log('SUCCESS!', response.status, response.text);
      setFormData({
        name:'',
        email:"",
        mobile:"",
        message:""
      });
    }).catch((error) => {
      console.error("FAILED...", error);
    });
  }

  const whatsappNumber = '+971528722900'; // Your WhatsApp number
  const whatsappMessage = 'Hello! I would like to know more about your services.'; // Default message
  const whatsappLink = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(whatsappMessage)}`;


  return (
    <>  
      <NewPageNavbar />
        <section>
          <Row className='contact-bg'>
            <Container className='p-0'>
              <Col md={12}>
                <div className='contactus'>
                  <h2>Contact Us</h2>
              
                  <div className='socialmedia'>

                     <a href="https://www.linkedin.com/company/maxims-auditors-consultants-pvt-ltd/" target="_blank" className="mt-1 whatsapp-button" rel="noopener noreferrer">
                        <FaLinkedin />
                      </a>

                     <a href={whatsappLink} target="_blank" rel="noopener noreferrer" className="mt-1 whatsapp-button">
                      <FaWhatsapp/>
                    </a>

                  </div>
                </div>
              </Col>
            </Container>
          </Row>
          <Row className='form-row'>

          {/* <Col md={6} lg={5} className=''> 
            <div className='p-5 next-step'>
              <div>
                <h3>What will be next step?</h3>
                <h5>You are one step closer to build your product perfect</h5>
              </div>

              <div className='mt-3'>
                <ol>
                  <li>
                    <p>Continuous learning enhances knowledge and skills, enabling personal and professional growth.</p>
                  </li>
                  <li>
                    <p>Building strong relationships fosters support networks and opportunities for collaboration.</p>
                  </li>
                  <li>
                    <p>Proactively tackle challenges, implement strategies, and adapt to changes to achieve desired outcomes.</p>
                  </li>
                </ol>
              </div>
            </div>
          </Col> */}

          {/* <Col md={1} className=''> </Col> */}
          <Col md={6} lg={5}></Col>

          <Col md={6} lg={6} className='pe-md-5 form-col'>
            <div className='contact-border'>
              <div  className='contact-form p-3 pb-0'>
                <p className='envelope px-3 py-1'><FaEnvelope /></p>
                <p className='ms-3'>
                  Write as a few words about your project and we will prepare a proposal for you within 
                  24 hours.
                </p>
              </div>

              <div>
                <Form onSubmit={handleSubmit}>
                  <Row className='p-4'>
                    <Col lg={12}>
                      <div className='pt-4'>
                        <input className='form-input p-2' onChange={handleChange} type="text" id="name" name="name" value={formData.name} placeholder="User name" />
                      </div>
                    </Col>
                    <Col lg={12} xl={6}>
                      <div className='pt-4'>
                        <input className='form-input p-2' onChange={handleChange} type="email" id="email" name="email" value={formData.email} placeholder="Email address" />
                      </div>
                    </Col>
                    <Col lg={12} xl={6} className='pt-4'>
                      <div>
                        <input className='form-input p-2' onChange={handleChange} type="text" id="mobile" name="mobile" value={formData.mobile} placeholder="Mobile Number" />
                      </div>
                    </Col>
                    <Col lg={12} className='pt-4'>
                      <div>
                        <textarea className='form-input message p-2' onChange={handleChange} type="text" id="message" name="message" value={formData.message} placeholder="Message" />
                      </div>
                    </Col>
                  </Row>
                  <Row className='px-4 text-center'>
                    <Col md={12} className='pt-4 pb-2'>
                      <button className='submit' type="submit">Submit</button>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </Col>
          <Col md={1} className=''> </Col>

        
          </Row>
       
      </section>
    </>
  )
}

export default Contact
