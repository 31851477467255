import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Row, Col, Carousel } from 'react-bootstrap';
import { blogData } from '../../utils/data';
import NewPageNavbar from '../NewPageNavbar/NewPageNavbar';
import './ImageDetail.css'
import { FaCheck } from 'react-icons/fa';

const ImageDetail = () => {
  const { slug } = useParams(); 
  const post = blogData.find(img => img.slug === slug);

  if (!post) {
    return <div>Image not found</div>;
  }

  const listStyle = {
    listStyleType: 'none',
  };

  return (
      <>
        <NewPageNavbar />
        <Row className='me-0'>
        <div className='hero-images'>
          <Col md={12} className='p-0'>
            <div className='image-container'>
              <img className='blog-images'
                src={post.heroblog} 
                alt={post.alt} 
                style={{ width: '100%' }} 
              />
                  
          
                <div style={{ 
                  position: 'absolute', 
                  top: '50%', 
                  left: '5%', 
                  transform: 'translateY(-50%)', 
                  zIndex: '3',
                  color:'white',
                  borderRadius: '5px'                   
                }}>
                  <Row className='m-0'>
                    <Col xs={11} sm={11} md={8} className='pb-xs-5'>
                      <div className=''>
                        <h1 className='imageDetail-title'>{post.title}</h1>
                        <p className='mt-5 imageDetail-para' style={{color:'white'}}>{post.metaDescription}</p>
                      </div>
                    <div style={{textAlign:'right'}}>
                    <p className='mb-0'>
                        <Link to='/' className='blog-home'><span style={{cursor:"pointer"}} >Home</span></Link>
                        <span className='mx-2'>|</span>
                        <Link to='/blog' className='blog-home'><span style={{cursor:"pointer"}}>Blog</span></Link>
                      </p>
                    </div>
                    </Col>
                  </Row>
                </div> 
            </div>
          </Col>       
          </div>
      </Row>

  <Row className='m-5'>
    <Col md={12} lg={8} className='px-md-4 px-sm-0'>
        {post.content.map((section, index) => (
          <>
          <section key={index}>
            <div>
                {section.heading && <h2 className='imageDetail-title'>{section.heading}</h2> }
                {section.text && <h6 className='imageDetail-para'  style={{textAlign:'justify'}}>{section.text}</h6>}  
                {section.cryptotext && <h6 className='imageDetail-para'  style={{textAlign:'justify'}}>{section.cryptotext}</h6>}
                
                {section.src &&  
                  <div className='p-3'>
                    <img style={{width:'100%'}} src={section.src} alt={section.alt} />
                  </div>  
              }           

          {section.list && (
            <ul style={{textAlign:"justify"}}>
              {section.list.map((item, i) => (
                <li key={`${index}-${i}`}  style={listStyle}><b><FaCheck className="me-3 facheck" /><span className="imageDetail-para">{item}</span></b></li>
              ))}
            </ul>
          )}

            {section.listtitle && (
              <div>
                {section.listtitle.map((item, i) => (
                  <ul key={`${index}-${i}`} style={{textAlign:'justify'}}>
                    <li  style={listStyle}><b><FaCheck className="me-3 facheck" /><span className="imageDetail-para paralist-head">{item.head}</span></b>
                    <span className='imageDetail-para'>{item.text}</span></li>
                  </ul>
                ))}
              </div>
            )}

            {section.paralist && (
             <div>
                {section.paralist.map((item, i) => (
                 
                     <ul style={listStyle} key={`${index}-paralist-${i}`}>
                      <li><b><FaCheck className='me-3 facheck'/><span className='imageDetail-para paralist-head'>{item.head}</span></b></li>
                   
                      <li className='imageDetail-para'  style={{textAlign:'justify'}}>{item.content}</li>
                      
                      {item.list && item.list.map((subItem, subIndex) => (
                        <li key={`${index}-${i}-${subIndex}`} className='ms-5 mt-3'>
                          <b>{subItem.list}</b>
                          <ul className='ms-4' style={listStyle}>
                            <li>{subItem.code}</li>
                          </ul>
                        </li>
                      ))}

                    </ul>
                 
                ))}
              </div> 
            )}   
            {section.conclusion && <h2 className='imageDetail-title'>{section.conclusion}</h2> }

              {section.text1 && <h6 className='imageDetail-para'  style={{textAlign:'justify'}}>{section.text1}</h6>}
              {/* {section.src && <div className="centered-image-container">
                  <img src={section.src} alt={section.heading} />
                </div>} */}
              {section.text2 && <h6 className='imageDetail-para'  style={{textAlign:'justify'}}>{section.text2}</h6>}
              {section.text3 && <h6 className='imageDetail-para'  style={{textAlign:'justify'}}>{section.text3}</h6>}
            </div>    
          </section>
          </>
        ))}
    </Col>

    <Col md={12} lg={4}>
        <Row>
        <h5 className='recentpost-heading mt-2'>RECENT POST</h5>
          {/* <Col lg={12} md={6} className='pt-3'> */}
            {post.recentpost && post.recentpost.map((images, index) => (
              <>
              <Col lg={12} md={6} xs={12} sm={12} className='pt-3'>
                <div key={index} className='recent_post mt-4 mb-3'>
                    <Link to={'/blog'} className='link-text'>
                      <div className='recent-image' style={{width:'100px', height:'70px'}}>
                        <img style={{width:"100%", height:"100%"}} src={images.src} alt={images.alt} />
                      </div>
                    </Link>
                    <Link to={'/blog'} className='link-text'>
                      <div className='ps-3'>
                        <h6 className='link-heading'>{images.heading}</h6>
                        <h6 className='date'>{images.date}</h6>
                      </div>
                    </Link>
                </div>
                </Col>
              </>
            ))}
          
        </Row>

        <Row>
          <Col md={12}>
             {post.learnmore.map((item, index) => (
              <>
                <div className='my-5 recommed' key={index} style={{backgroundColor:"blue", width: '100%', height:'50vh'}}>
                  <div className='p-5'>
                      <h3><b>{item.name}</b></h3>
                      <p className='pt-3'>{item.para}</p>
                  </div>
                </div>
              </>
             ))}
          </Col>
        </Row>

        <Row>
          <Col md={12} className='pt-5'>
          
          <h5 className='recentpost-heading'>FEATURED POST</h5>
          <Carousel className='mt-4'>
        {post.featuredpost.map((post, index) => (
          <Carousel.Item key={index}>
            <img
              className="d-block w-100"
              src={post.src}
              alt={post.alt}
            />
            <div>
              <h5 className='mt-3' style={{color:'black'}}><b>{post.text}</b></h5>
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
      
          </Col>
        </Row>
    </Col>
  </Row>



{/* <Container>

<div className="">
  
      <h1>{post.title}</h1>
      <meta name="description" key={post.id} content={post.metaDescription} />
      
      {post.content.map((section, index) => (
        <section key={index}>
          <h2 className='imageDetail-title'>{section.heading}</h2>
            
            {section.text && <p className='imageDetail-para'>{section.text}</p>}

          {section.list && (
            <ul>
              {section.list.map((item, i) => (
                <li key={`${index}-${i}`}>{item}</li>
              ))}
            </ul>
          )}

          {section.paralist && (
             <div>
                {section.paralist.map((item, i) => (
                  <div key={`${index}-paralist-${i}`}>
                    <p><b>{item.head}</b></p>
                    <ul>
                      <li>{item.content}</li>
                      {item.list && item.list.map((subItem, subIndex) => (
                        <li key={`${index}-${i}-${subIndex}`} className='ms-5 mt-3'>
                          <b>{subItem.list}</b>
                          <ul className='ms-4' style={listStyle}>
                            <li>{subItem.code}</li>
                          </ul>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
             </div>
          )}

          {section.text1 && <p>{section.text1}</p>}
          {section.src && <div className="centered-image-container">
              <img src={section.src} alt={section.heading} />
            </div>}
          {section.text2 && <p>{section.text2}</p>}
          {section.text3 && <p>{section.text3}</p>}
        </section>
      ))}
    </div>
</Container> */}

      </>
  );
};

export default ImageDetail;
